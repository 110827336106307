import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import ConstantMessages, { NOTIFY_MESSAGE } from '../utils/constants-messages/constants-messages';
import { SNACK_BAR } from '../utils/constants';

@Injectable()
export class ClipBoardService {
  constructor(private readonly snackBar: MatSnackBar) {}

  copy(text: string): void {
    // TODO ⚽ --> sustituir función cuando se actualice angular materials --> https://material.angular.io/cdk/clipboard/overview
    const selectBox = document.createElement('textarea');
    selectBox.style.position = SNACK_BAR.STYLE.POSITION;
    selectBox.style.left = SNACK_BAR.STYLE.LEFT;
    selectBox.style.top = SNACK_BAR.STYLE.TOP;
    selectBox.style.opacity = SNACK_BAR.STYLE.OPACITY;
    selectBox.value = text;
    document.body.appendChild(selectBox);
    selectBox.focus();
    selectBox.select();
    document.execCommand('copy');
    document.body.removeChild(selectBox);

    this.snackBar.open(NOTIFY_MESSAGE.CLIPBOARD.SUCCESS, ConstantMessages.AcceptText, SNACK_BAR.CONFIGURATION);
  }
}
