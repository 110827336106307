import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';
import { CONSTANTSENDPOINTS } from './constants-urls/constants-endpoints';

export enum TIME_PERIODS {
  MONTHLY =  "monthly",
  WEEKLY ="weekly",
  BIWEEKLY ="biweekly",
  DAILY ="daily",
}
export class CONSTANTS {
  //Safari push notification
  public static readonly API_REST_IOS_NOTIFICATION =
    environment.iosNotificationAPI.baseUrl + environment.iosNotificationAPI.prefixPackage;
  public static readonly API_REST_IOS_TOPICS =
    environment.iosNotificationAPI.messagingUrl + environment.iosNotificationAPI.prefixTopics;
  public static readonly API_REST_IOS_SEND_NOTIFICATION =
    environment.iosNotificationAPI.messagingUrl + environment.iosNotificationAPI.prefixSend;

  // HIPATIA SERVICES
  public static readonly API_REST_COMPONENTS_USING_SEGMENT_URL_NEST = `${environment.hipatiaServicesApi}segment/componentsUsingSegment`;
  public static readonly API_REST_UPDATE_SITES_TYPE_WALL = `${environment.hipatiaServicesApi}trigger/updateSitesTypeWall`;
  public static readonly API_REST_UPDATE_SITES_SCRIPT_FEATURES = `${environment.hipatiaServicesApi}features/:mediaId`;
  public static readonly API_REST_UPDATE_MEDIAS_TYPE_WALL = `${environment.hipatiaServicesApi}trigger/updateMediasTypeWall`;
  public static readonly API_REST_GET_HTML_WIDGET = `${environment.widgetRendererAPI}web/widget/:media/:widget`;
  public static readonly UPLOAD_IMAGE_URL = `${environment.hipatiaServicesApi}image/upload`;
  // METRICS
  public static readonly API_REST_REAL_TIME_CONCURRENT_USERS_URL =
    environment.realTimeAPI.baseURL + environment.realTimeAPI.concurrentUsersPath;
  public static readonly API_REST_REAL_TIME_CONCURRENT_USERS_URL_BY_DEVICE =
    environment.realTimeAPI.baseURL + environment.realTimeAPI.concurrentUsersPathDevice;
  public static readonly API_REST_REAL_TIME_CONCURRENT_PAGE_VIEWS_URL =
    environment.realTimeAPI.baseURL + environment.realTimeAPI.concurrentPageViewsPath;
  public static readonly API_REST_REAL_TIME_CONCURRENT_PAGE_VIEWS_URL_BY_DEVICE =
    environment.realTimeAPI.baseURL + environment.realTimeAPI.concurrentPageViewsPathDevice;

  // **********SEGMENTS***********
  public static readonly SEGMENTS_TYPES_NAMES = ['histórico', 'real time', 'importado'];

  public static readonly API_REST_SEGMENTS_GENERATOR_URL =
    environment.segmentsAPI.baseURL + environment.segmentsAPI.prefixGenerator;

  // IMPORTED SEGMENTS
  public static readonly API_RULES_OPERATION_UPDATE_IMPORTED_SEGMENT_COMPONENTS =
    environment.rulesegmentAPI.baseURL + environment.rulesegmentAPI.updateImportedSegmentComponents;

  // HISTORICAL SEGMENTS
  public static readonly API_REST_SEGMENTS_HISTORICAL_URL =
    environment.segmentsAPI.baseURL +
    environment.segmentsAPI.prefixGenerator +
    environment.segmentsAPI.prefixHistorical;
  public static readonly API_SEGMENT_AUTHOR = environment.segmentsAPI.prefixAuthor;
  public static readonly API_SEGMENT_LAYOUT = environment.segmentsAPI.prefixLayout;
  public static readonly API_RULES_SEGMENT_USERS =
    environment.rulesegmentAPI.baseURL + environment.rulesegmentAPI.segmentHistoricalUsers;
  public static readonly API_RULES_SEGMENT_CREATE =
    environment.rulesegmentAPI.baseURL + environment.rulesegmentAPI.segmentHistoricalCreate;
  public static readonly API_RULES_SEGMENT_UPDATE =
    environment.rulesegmentAPI.baseURL + environment.rulesegmentAPI.segmentHistoricalUpdate;
  public static readonly API_RULES_SEGMENT_DELETE =
    environment.rulesegmentAPI.baseURL + environment.rulesegmentAPI.segmentHistoricalDelete;
  public static readonly API_RULES_OPERATION_UPDATE_SEGMENT_COMPONENTS =
    environment.rulesegmentAPI.baseURL + environment.rulesegmentAPI.updateHistoricalSegmentComponents;
  public static readonly API_RULES_OPERATION_SEGMENT_USERS =
    environment.rulesegmentAPI.baseURL + environment.rulesegmentAPI.segmentHistoricalOperationUsers;
  public static readonly API_RULES_OPERATION_SEGMENT_CREATE =
    environment.rulesegmentAPI.baseURL + environment.rulesegmentAPI.segmentHistoricalOperationCreate;
  public static readonly API_RULES_OPERATION_SEGMENT_UPDATE =
    environment.rulesegmentAPI.baseURL + environment.rulesegmentAPI.segmentHistoricalOperationUpdate;
  public static readonly API_RULES_OPERATION_SEGMENT_DELETE =
    environment.rulesegmentAPI.baseURL + environment.rulesegmentAPI.segmentHistoricalOperationDelete;
  public static readonly API_RULES_SEGMENTS =
    environment.rulesegmentAPI.baseURL + environment.rulesegmentAPI.segmentsHistorical;
  public static readonly API_RULES_SEGMENTS_APPS =
    environment.rulesegmentAPI.baseURL + '/api/segment/historical/segments/app/:mediaId';
  public static readonly API_ADMANAGER_SEGMENTS_ADD = environment.admanagerSegmentsApi.baseUrl + '/addTargetingValue';
  public static readonly API_ADMANAGER_SEGMENTS_DELETE =
    environment.admanagerSegmentsApi.baseUrl + '/deleteTargetingValue';
  public static readonly API_ADMANAGER_SEGMENTS_EXISTS =
    environment.admanagerSegmentsApi.baseUrl + '/getTargetingExists';
  public static readonly API_ADMANAGER_KEY_TARGETING = environment.admanagerSegmentsApi.keyTargeting;

  public static readonly PUSH_CONFIG_UPLOAD = environment.fcmCredentials.baseURL + environment.fcmCredentials.upload;
  public static readonly PUSH_CONFIG_UPLOAD_APP =
    environment.fcmCredentials.baseURL + environment.fcmCredentials.uploadApp;

  // REALTIME SEGMENTS
  public static readonly API_REST_SEGMENTS_REALTIME_URL =
    environment.segmentsAPI.baseURL + environment.segmentsAPI.prefixGenerator + environment.segmentsAPI.prefixRealTime;

  public static readonly API_REST_UPDATE_TRIGGER_VERSION =
    environment.triggerVersion.baseUrl + 'trigger/version/:mediaId';

  public static readonly API_REST_UPDATE_SEGMENT_VERSION =
    environment.triggerVersion.baseUrl + 'segment/version/:mediaId';

  public static readonly API_REST_DELETE_WIDGET_MODAL =
    environment.deleteCacheAPI.widgetBaseUrl + environment.deleteCacheAPI.deleteCacheWidget;

  public static readonly API_REST_DELETE_TRIGGER_APP = `${environment.prometeoAppsAPI}/v1/cache/trigger/:mediaId`;

  public static readonly API_REST_DELETE_WIDGET_APP = `${environment.prometeoAppsAPI}/v1/cache/widget/:mediaId/:widgetId`;

  public static readonly API_REST_DELETE_TESTAB_WIDGET = `${environment.deleteCacheAPI.widgetBaseUrl}/test-ab-widgets/:mediaId/:idTestAbWidget`;

  public static readonly API_REST_DELETE_REAL_TIME_SEGMENT_APP = `${environment.prometeoAppsAPI}/v1/cache/real-time-segment/:mediaId`;

  public static readonly API_REST_DELETE_DATASOURCE_APP = `${environment.prometeoAppsAPI}/v1/cache/datasource/:mediaId/:type/:datasourceId`;
  public static readonly API_REST_DELETE_DATASOURCE =
    environment.deleteCacheAPI.widgetBaseUrl + environment.deleteCacheAPI.deleteCacheDatasource;

  public static readonly API_REST_UPDATE_CONFIG =
    environment.deleteCacheAPI.widgetBaseUrl + environment.deleteCacheAPI.updateConfig;

  public static readonly API_REST_UPDATE_PROMETEO_CONFIG =
    environment.deleteCacheAPI.widgetBaseUrl + 'prometeoconfig/:mediaId';

  public static readonly API_REST_HENNEO_RECOMMENDATIONS_TOPICS_BY_MEDIA_URL =
    environment.henneoRecommendationsUrl.baseUrl + environment.henneoRecommendationsUrl.topicsByMediaId;
  public static readonly API_REST_HENNEO_RECOMMENDATIONS_RECOMMENDATION_BY_TYPE =
    environment.henneoRecommendationsUrl.baseUrl + environment.henneoRecommendationsUrl.recommendationByType;

  public static readonly MEDIA_CONTENT_API_SECTIONS_BY_MEDIA_URL = environment.mediaContentURL + '/categories';
  public static readonly MEDIA_CONTENT_API_LAYOUTS_BY_MEDIA_URL = environment.mediaContentURL + '/layouts';
  public static readonly MEDIA_CONTENT_API_ACCESSTYPES_BY_MEDIA_URL = environment.mediaContentURL + '/accesstypes';

  // Newsletter API REST URLS

  public static readonly API_REST_NEWSLETTER_URL =
    environment.newsletterAPI.baseUrl + environment.newsletterAPI.prefixNewsletter;
  public static readonly CREATE_CAMPAIGN = environment.newsletterAPI.createCampaign;
  public static readonly SEND_CAMPAIGN = environment.newsletterAPI.sendCampaign;
  public static readonly GET_CAMPAIGNS = environment.newsletterAPI.getCampaigns;
  public static readonly CREATE_TAG = environment.newsletterAPI.createTag;
  public static readonly GET_TAGS = environment.newsletterAPI.getTags;
  public static readonly GET_TEMPLATES = environment.newsletterAPI.getTemplates;
  public static readonly NEWSLETTER_ADD_TEMPLATE = environment.newsletterAPI.assignTemplate;
  public static readonly NEWSLETTER_ADD_TEMPLATE_AND_REPLICATE_CAMPAIGN =
    environment.newsletterAPI.assignTemplateAndReplicateCampaign;
  public static readonly NEWSLETTER_GET_CAMPAIGN_BY_ID = environment.newsletterAPI.getCampaignById;
  public static readonly NEWSLETTER_GET_CAMPAIGN_CONTENT = environment.newsletterAPI.getCampaignContentById;
  public static readonly NEWSLETTER_GET_AUDIENCES = environment.newsletterAPI.getAudiences;
  public static readonly NEWSLETTER_WEBHOOK = environment.newsletterAPI.webhook;
  public static readonly API_REST_NEWSLETTER_URL_V2 =
    environment.newsletterAPI.baseUrl + environment.newsletterAPI.prefixNewsletter;
  public static readonly API_REST_SUBSCRIPTIONS_URL_V2 =
    environment.newsletterAPI.baseUrl + environment.newsletterAPI.prefixSubscriptions;
  public static readonly CREATE_WEBHOOK_V2 =
    environment.newsletterAPI.baseUrl +
    environment.newsletterAPI.prefixNewsletterSyncV2 +
    environment.newsletterAPI.createWebhookV2;
  public static readonly DEFAULT_LOGIN_SCREENSET = 'Default-RegistrationLogin';

  // Push Notification API URL

  public static readonly API_REST_PUSH_NOTIFICATION_URL =
    environment.pushNotificationAPI.baseUrl + environment.pushNotificationAPI.prefixPush;
  public static readonly API_SCHEDULE_CREATE =
    environment.scheduleNotification.baseUrl + environment.scheduleNotification.prefixSchedule;
  public static readonly API_SCHEDULE_SEND_MULTIPLE_TOPICS =
    environment.scheduleNotification.baseUrl + environment.scheduleNotification.prefixSchedule + environment.scheduleNotification.multiple;
  public static readonly API_SCHEDULE_UPDATE =
    environment.scheduleNotification.baseUrl + CONSTANTSENDPOINTS.scheduledNotification.prefixScheduleUpdate;
  public static readonly API_SCHEDULE_APP =
    environment.scheduleNotification.baseUrl + CONSTANTSENDPOINTS.scheduledNotification.prefixScheduleApp;
  public static readonly API_SCHEDULE_APP_DELETE =
    environment.scheduleNotification.baseUrl + CONSTANTSENDPOINTS.scheduledNotification.prefixScheduleDeleteApp;

  public static readonly PUSH_SHOW_TOPICS = environment.pushNotificationAPI.showTopics;
  public static readonly PUSH_UTM_SOURCE = 'hipatia-push-web';
  public static readonly PUSH_UTM_MEDIUM = 'Push Notifications';

  // Push notification credentials and scripts

  public static readonly API_REST_PUSH_NOTIFICATION_CREDENTIALS_SCRIPT_URL =
    environment.fcmCredentials.baseURL + environment.fcmCredentials.prefixNotification;

  public static readonly CLOUDFRONT_URL = environment.cloudfrontURL;
  public static readonly EMAIL_ACTIONS_URL = environment.emailActionsURL;

  public static readonly MAX_FILE_SIZE = 500000;
  public static readonly MAX_SMALL_FILE_SIZE = 300000;

  public static readonly PROMETEO_WIDGETS_NORMAL_MODE: number = 0;
  public static readonly PROMETEO_WIDGETS_STICKY_BOTTOM_MODE: number = 1;
  public static readonly PROMETEO_WIDGETS_STICKY_TOP_MODE: number = 2;
  public static readonly PROMETEO_WIDGETS_SIDEBAR_MODE: number = 3;
  public static readonly PROMETEO_WIDGETS_NORMAL_MODE_LITERAL: string = 'Normal';
  public static readonly PROMETEO_WIDGETS_STICKY_BOTTOM_MODE_LITERAL: string = 'Sticky bottom';
  public static readonly PROMETEO_WIDGETS_STICKY_TOP_MODE_LITERAL: string = 'Sticky top';
  public static readonly PROMETEO_WIDGETS_SIDEBAR_MODE_LITERAL: string = 'Sidebar';

  public static readonly PROMETEO_ARTICLE_JSON_AUTO_TYPE: string = 'j';
  public static readonly PROMETEO_ARTICLE_CUSTOM_TYPE: string = 'c';
  public static readonly PROMETEO_ARTICLE_HTML_TYPE: string = 'h';
  public static readonly PROMETEO_ARTICLE_MACHINE_LEARNING_TYPE = 'mla';
  public static readonly PROMETEO_ARTICLE_SEGMENTED_DATASOURCE_TYPE = 'sds';
  public static readonly PROMETEO_ARTICLE_OUTBRAIN_TYPE = 'ob';
  public static readonly PROMETEO_ARTICLE_NATIVE_TYPE = 'n';
  public static readonly PROMETEO_ARTICLE_ACCESS_TYPE_LIST = ['suscripcion', 'registro', 'abierto', 'vacio'];

  public static readonly PROMETEO_TRIGGERS_HOME_LAYOUT: number = 1;
  public static readonly PROMETEO_TRIGGERS_ARTICLE_LAYOUT: number = 2;
  public static readonly PROMETEO_TRIGGERS_ARTICLE_LAYOUT_LITERAL: string = 'Artículo';
  public static readonly PROMETEO_TRIGGERS_HOME_LAYOUT_LITERAL: string = 'Home';

  public static readonly PROMETEO_TRIGGERS_MODAL_TYPE: number = 1;
  public static readonly PROMETEO_TRIGGERS_STICKY_TOP_TYPE: number = 2;
  public static readonly PROMETEO_TRIGGERS_STICKY_BOTTOM_TYPE: number = 3;
  public static readonly PROMETEO_TRIGGERS_SIDEBAR_TYPE: number = 4;
  public static readonly PROMETEO_TRIGGERS_PUSH_TYPE: number = 5;
  public static readonly PROMETEO_TRIGGERS_NEWSLETTER_TYPE: number = 6;
  public static readonly PROMETEO_TRIGGERS_NONE_TYPE: number = 7;
  public static readonly PROMETEO_TRIGGERS_BOX_TYPE: number = 8;
  public static readonly PROMETEO_TRIGGERS_MODAL_TYPE_LITERAL: string = 'Modal';
  public static readonly PROMETEO_TRIGGERS_STICKY_TOP_TYPE_LITERAL: string = 'Sticky top';
  public static readonly PROMETEO_TRIGGERS_STICKY_BOTTOM_TYPE_LITERAL: string = 'Sticky bottom';
  public static readonly PROMETEO_TRIGGERS_SIDEBAR_TYPE_LITERAL: string = 'Sidebar';
  public static readonly PROMETEO_TRIGGERS_PUSH_TYPE_LITERAL: string = 'Notificación push';
  public static readonly PROMETEO_TRIGGERS_PUSH_TYPE_PLURAL_LITERAL: string = 'Notificaciones push';
  public static readonly PROMETEO_TRIGGERS_NONE_TYPE_LITERAL: string = 'Ninguno';
  public static readonly PROMETEO_TRIGGERS_ALL_TYPE_LITERAL: string = 'Todos';
  public static readonly PROMETEO_TRIGGERS_TRIGGER_TYPE_PLURAL_LITERAL: string = 'Activadores';
  public static readonly PROMETEO_TRIGGERS_NEWSLETTER_TYPE_LITERAL: string = 'Newsletter';
  public static readonly PROMETEO_TRIGGERS_NEWSLETTER_TYPE_PLURAL_LITERAL: string = 'Newsletters';
  public static readonly PROMETEO_TRIGGERS_BOX_TYPE_LITERAL: string = 'Box';

  public static readonly PROMETEO_SCHEDULES_MESSAGE_MANUAL_TYPE: number = 1;
  public static readonly PROMETEO_SCHEDULES_MESSAGE_DYNAMIC_TYPE: number = 2;
  public static readonly PROMETEO_SCHEDULES_MESSAGE_MANUAL_TYPE_LITERAL: string = 'Manual';
  public static readonly PROMETEO_SCHEDULES_MESSAGE_DYNAMIC_TYPE_LITERAL: string = 'Dinámico';

  public static readonly PROMETEO_SCHEDULES_NOW_TYPE: number = 1;
  public static readonly PROMETEO_SCHEDULES_SCHEDULED_TYPE: number = 2;
  public static readonly PROMETEO_SCHEDULES_PERIODICALLY_TYPE: number = 3;
  public static readonly PROMETEO_SCHEDULES_NOW_TYPE_LITERAL: string = 'Inmediato';
  public static readonly PROMETEO_SCHEDULES_SCHEDULED_TYPE_LITERAL: string = 'Planificado';
  public static readonly PROMETEO_SCHEDULES_PERIODICALLY_TYPE_LITERAL: string = 'Periódico';

  public static readonly PROMETEO_SCHEDULES_ENDPOINT_MOSTREAD_TYPE: number = 1;
  public static readonly PROMETEO_SCHEDULES_ENDPOINT_MOSTREAD_TYPE_LITERAL: string = 'Lo más Leído';
  public static readonly PROMETEO_SCHEDULES_ENDPOINT_MOSTRECENT_TYPE: number = 2;
  public static readonly PROMETEO_SCHEDULES_ENDPOINT_MOSTRECENT_TYPE_LITERAL: string = 'Lo más Reciente';

  public static readonly PROMETEO_CREATE_ACTION_TYPE: number = 1;
  public static readonly PROMETEO_CREATE_ACTION_TYPE_LITERAL: string = 'create';
  public static readonly PROMETEO_UPDATE_ACTION_TYPE: number = 2;
  public static readonly PROMETEO_UPDATE_ACTION_TYPE_LITERAL: string = 'update';
  public static readonly PROMETEO_DELETE_ACTION_TYPE: number = 3;
  public static readonly PROMETEO_DELETE_ACTION_TYPE_LITERAL: string = 'delete';
  public static readonly PROMETEO_ACCOMPLISH_ACTION_TYPE: number = 4;
  public static readonly PROMETEO_ACCOMPLISH_ACTION_TYPE_LITERAL: string = 'accomplish';

  public static readonly PROMETEO_WIDGET_ENTITY_TYPE: number = 1;
  public static readonly PROMETEO_WIDGET_ENTITY_TYPE_LITERAL: string = 'widget';
  public static readonly PROMETEO_WIDGET_ENTITY_TYPE_PLURAL_LITERAL: string = 'widgets';
  public static readonly PROMETEO_DATASOURCE_ENTITY_TYPE: number = 2;
  public static readonly PROMETEO_DATASOURCE_ENTITY_TYPE_LITERAL: string = 'datasource';
  public static readonly PROMETEO_DATASOURCE_ENTITY_TYPE_PLURAL_LITERAL: string = 'datasources';
  public static readonly PROMETEO_WIDGET_TEMPLATE_ENTITY_TYPE: number = 3;
  public static readonly PROMETEO_WIDGET_TEMPLATE_ENTITY_TYPE_LITERAL: string = 'widget template';
  public static readonly PROMETEO_TRIGGER_ENTITY_TYPE: number = 4;
  public static readonly PROMETEO_TRIGGER_ENTITY_TYPE_LITERAL: string = 'trigger';
  public static readonly PROMETEO_SEGMENT_ENTITY_TYPE: number = 5;
  public static readonly PROMETEO_SEGMENT_ENTITY_TYPE_LITERAL: string = 'Segmento histórico';
  public static readonly PUSH_SUBSCRIPTION_ENTITY_TYPE: number = 6;
  public static readonly PUSH_SUBSCRIPTION_ENTITY_TYPE_LITERAL: string = 'push subscription';
  public static readonly NEWSLETTER_SUBSCRIPTION_ENTITY_TYPE: number = 7;
  public static readonly NEWSLETTER_ENTITY_TYPE_LITERAL: string = 'newsletter subscription';
  public static readonly PROMETEO_SEGMENTED_DATASOURCE_ENTITY_TYPE: number = 8;
  public static readonly PROMETEO_SEGMENTED_DATASOURCE_ENTITY_TYPE_LITERAL: string = 'segmented datasource';
  public static readonly PROMETEO_TRIGGER_TEMPLATE_ENTITY_TYPE: number = 9;
  public static readonly PROMETEO_TRIGGER_TEMPLATE_ENTITY_TYPE_LITERAL: string = 'Plantilla de activador';
  public static readonly PROMETEO_PUSH_SUBSCRIPTION_TEMPLATE_ENTITY_TYPE: number = 10;
  public static readonly PROMETEO_PUSH_SUBSCRIPTION_TEMPLATE_ENTITY_TYPE_LITERAL: string =
    'Plantilla de push subscription';
  public static readonly PROMETEO_NEWSLETTER_SUBSCRIPTION_TEMPLATE_ENTITY_TYPE: number = 11;
  public static readonly PROMETEO_NEWSLETTER_SUBSCRIPTION_TEMPLATE_ENTITY_TYPE_LITERAL: string =
    'Plantilla de suscripción a newsletter';
  public static readonly PROMETEO_TEST_AB_ENTITY_TYPE: number = 12;
  public static readonly PROMETEO_TEST_AB_ENTITY_TYPE_LITERAL: string = 'test-ab';
  public static readonly PROMETEO_TEST_AB_WIDGETS_ENTITY_TYPE: number = 13;
  public static readonly PROMETEO_TEST_AB_WIDGETS_ENTITY_TYPE_LITERAL: string = 'test-ab widgets';
  public static readonly PROMETEO_OPERATION_SEGMENT_ENTITY_TYPE: number = 14;
  public static readonly PROMETEO_OPERATION_SEGMENT_ENTITY_TYPE_LITERAL: string = 'operation segment';

  // Newsletter status type
  public static readonly NEWSLETTER_STATUS_SAVE_LITERAL: string = 'save';
  public static readonly NEWSLETTER_STATUS_PAUSED_LITERAL: string = 'paused';
  public static readonly NEWSLETTER_STATUS_SCHEDULE_LITERAL: string = 'schedule';
  public static readonly NEWSLETTER_STATUS_SENDING_LITERAL: string = 'sending';
  public static readonly NEWSLETTER_STATUS_SENT_LITERAL: string = 'sent';
  public static readonly NEWSLETTER_STATUS_SAVE_LITERAL_COLOR: string = 'success';
  public static readonly NEWSLETTER_STATUS_PAUSED_LITERAL_COLOR: string = 'warning';
  public static readonly NEWSLETTER_STATUS_SCHEDULE_LITERAL_COLOR: string = 'danger';
  public static readonly NEWSLETTER_STATUS_SENDING_LITERAL_COLOR: string = 'danger';
  public static readonly NEWSLETTER_STATUS_SENT_LITERAL_COLOR: string = 'danger';

  public static readonly MONDAY: number = 1;
  public static readonly MONDAY_LITERAL: string = 'Lunes';
  public static readonly TUESDAY: number = 2;
  public static readonly TUESDAY_LITERAL: string = 'Martes';
  public static readonly WEDNESDAY: number = 3;
  public static readonly WEDNESDAY_LITERAL: string = 'Miércoles';
  public static readonly THURSDAY: number = 4;
  public static readonly THURSDAY_LITERAL: string = 'Jueves';
  public static readonly FRIDAY: number = 5;
  public static readonly FRIDAY_LITERAL: string = 'Viernes';
  public static readonly SATURDAY: number = 6;
  public static readonly SATURDAY_LITERAL: string = 'Sábado';
  public static readonly SUNDAY: number = 7;
  public static readonly SUNDAY_LITERAL: string = 'Domingo';

  // Period frecuency
  public static readonly TIME_PERIOD_FRECUENCY = [
    {
      key: TIME_PERIODS.MONTHLY,
      literal: 'Mensual'
    },
    {
      key: TIME_PERIODS.WEEKLY,
      literal: 'Semanal'
    },
    {
      key: TIME_PERIODS.BIWEEKLY,
      literal: 'Quincenal'
    },
    {
      key: TIME_PERIODS.DAILY,
      literal: 'Diario'
    },
  ];

  // Days of week config
  public static readonly DAYS_OF_WEEK_CRON_CONFIG = [
    {
      unixCronKey : 'sun',
      literal: 'Domingo',
    },
    {
      unixCronKey : 'mon',
      literal: 'Lunes',
    },
    {
      unixCronKey : 'tue',
      literal: 'Martes',
    },
    {
      unixCronKey : 'wed',
      literal: 'Miércoles',
    },
    {
      unixCronKey : 'thu',
      literal: 'Jueves',
    },
    {
      unixCronKey : 'fri',
      literal: 'Viernes',
    },
    {
      unixCronKey : 'sat',
      literal: 'Sábado',
    }
  ];
  public static readonly FIRSTDAY_OF_MONTH = 1;
  public static readonly LASTDAY_OF_MONTH = 31;

  // Role system
  public static readonly ROLE_KEYACCOUNT = 'keyaccount';
  public static readonly ROLE_ACCOUNT = 'account';
  public static readonly ROLE_PUSH_MANAGER = 'gestor push';
  public static readonly ROLE_PROPERTY = 'property';
  public static readonly ROLE_ADMIN = 'admin';
  public static readonly ROLE_READER = 'reader';
  public static readonly ROLE_NAVARRA = 'navarra';
  public static readonly ROLE_AXEL = 'axel';
  public static readonly ROLE_SERRA = 'serra';
  public static readonly ROLE_ADVERTISER = 'anunciante';
  public static readonly ROLE_OPERATOR = 'operador';
  public static readonly ROLE_PAYWALL_VIEWER = 'paywall viewer';
  public static readonly ROLE_PAYWALL_MANAGER = 'gestor paywall';

  public static readonly COPY_OF = 'copia de ';

  // Query Builder URL
  public static readonly QUERY_BUILDER = 'general-query';
  public static readonly MEDIA_ID_LIST = 'media-id-list';
  public static readonly ARTICLE_ID_LIST = 'article-id-list';
  public static readonly AUTHOR_LIST = 'author-list';
  public static readonly PATH_NAME_LIST = 'path-name-list';
  public static readonly REFERER_LIST = 'referer-list';
  public static readonly META_KEYWORD_LIST = 'meta-keyword-list';
  public static readonly META_URL_LIST = 'meta-url-list';
  public static readonly UA_OS_LIST = 'ua-os-list';
  public static readonly GEO_CITY_LIST = 'geo-city-list';
  public static readonly GEO_COUNTRY_LIST = 'geo-country-list';
  public static readonly GEO_REGION_LIST = 'geo-region-list';
  public static readonly SECTION_LIST = 'section-list';
  public static readonly SUB_SECTION_LIST = 'subsection-list';
  public static readonly SAVE_QUERY_BUILDER = 'save-segments-ds';
  public static readonly GET_LIST_SEGMENTS = 'list-segments';
  public static readonly UPDATE_SEGMENT_LIST = 'update-segments';
  public static readonly UPDATE_SEGMENTS_REALTIME = 'update-segments-realtime';
  public static readonly GET_SEGMENT_BY_ID = 'segments-by-id';
  public static readonly SAVE_REALTIME_DS = 'save-segment-realtime';
  public static readonly LIST_SEGMENTS_REALTIME_MEDIA = 'list-segments-real-time';
  public static readonly LIST_SEGMENTS_HISTORICAL_MEDIA = 'list-segments-historical';
  public static readonly DELETE_SEGMENT_BY_ID = 'segment-delete';

  // Segments types
  public static readonly SEGMENT_TYPE_HISTORICAL = 1;
  public static readonly SEGMENT_TYPE_REAL_TIME = 2;

  public static readonly TYPE_XML = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  //Trigger types priorities
  public static readonly TRIGGER_TYPE = 'Activador';
  public static readonly PUSH_SUBSCRIPTION_TYPE = 'Push Subscription';
  public static readonly NEWSLETTER_SUBSCRIPTION_TYPE = 'Newsletter Subscription';
  public static readonly TESTAB_TYPE = 'Test A/B';

  //Trigger types statistics
  public static readonly TRIGGER_TYPE_STATISTICS = 'Activador';
  public static readonly PUSH_NOTIFICATION_TYPE_STATISTICS = 'Notificación Push';
  public static readonly NEWSLETTER_TYPE_STATISTICS = 'Newsletters';
  public static readonly SEGMENTED_DATASOURCE_TYPE_STATISTICS = 'Fuente de Datos Segmentada';
  public static readonly WIDGET_TYPE_STATISTICS = 'Widget';

  //Triggers types
  public static readonly PROMETEO_ALL_TYPE = 1;
  public static readonly PROMETEO_TRIGGERS_TYPE = 2;
  public static readonly PROMETEO_PUSH_SUBSCRIPTION_TYPE = 3;
  public static readonly PROMETEO_NEWSLETTER_SUBSCRIPTION_TYPE = 4;

  public static readonly TRIGGER_NEWSLETTERS_TYPE = 'newsletter-subscription';

  //EVENTS FUNCTIONS
  public static readonly SHOW_EVENT = 'TRIGGER_EVENTS.show()';
  public static readonly ACTION_EVENT = 'TRIGGER_EVENTS.action()';
  public static readonly ACTION_EVENT_ID = 'TRIGGER_EVENTS.action(ID)';
  public static readonly CLOSE_EVENT = 'TRIGGER_EVENTS.close()';

  //TOKEN APPS
  public static readonly API_TOKEN_APPS_CREATE = environment.tokenApps.baseUrl + 'create';
  public static readonly API_TOKEN_APPS_ALL_TOKEN = environment.tokenApps.baseUrl;
  public static readonly API_TOKEN_APPS_UPDATE = environment.tokenApps.baseUrl + 'update';
  public static readonly API_TOKEN_APPS_DELETE = environment.tokenApps.baseUrl + 'delete';
  public static readonly API_TOKEN_APPS_DELETE_CREDENTIALS = `${environment.tokenApps.baseUrl}delete/credentials`;
  //USER FUNCTIONS
  public static readonly API_FUNCTION_DELETE_USER_FROM_AUTH =
    environment.cloudFunctionsAPI + '/deleteCompleteUser/user/:userId';

  //INFO ICONS COLORS
  public static readonly PRIMARY_COLOR = '#1B55E3';
  public static readonly RED_COLOR = 'red';
  public static readonly ORANGE_COLOR = 'orange';
  public static readonly RESOLVED_TEST_AB_WIDGETS_COLOR = '#1CBC0D';

  //METRIC DEVICES
  public static readonly DEVICE_DESKTOP_LITERAL: string = 'Desktop';
  public static readonly DEVICE_MOBILE_LITERAL_ES: string = 'Móvil';
  public static readonly DEVICE_TABLET_LITERAL: string = 'Tablet';
  public static readonly DEVICE_OTHERS_LITERAL_ES: string = 'Otros';

  //SITE TYPES
  public static readonly SITE_WEB: string = 'web';
  public static readonly SITE_APP: string = 'app';

  //MONTHS INTERVAL IN STATISTICS
  public static readonly STATISTICS_MONTH_INTERVAL: number = 4;
  public static readonly STATISTICS_ONE_MONTH_INTERVAL: number = 1;

  public static readonly STATISTICS_DAY_INTERVAL: number = 7;

  //SUPERADMIN ROLE ID
  public static readonly SUPERADMIN_ROLE: string = 'eWcY7OkneNdgfhZwwf8F';

  //FIREBASE AUTHENTICATION ACTIONS
  public static readonly RESET_PASSWORD_MODE: string = 'resetPassword';
  public static readonly RECOVER_EMAIL_MODE: string = 'recoverEmail';
  public static readonly VERIFY_EMAIL_MODE: string = 'verifyEmail';

  //USER ACCESS TYPES
  public static readonly USER_ACCESS_TYPE_MEDIAS = 'medias';
  public static readonly USER_ACCESS_TYPE_TEAMS = 'teams';

  //Numeric

  public static readonly FIVE_HUNDRED_THOUSAND_BYTES = 500000;

  public static readonly INPUT_NUMBER_VALUE_MIN: number = 0;

  public static readonly AES_KEY = 'p*c.s¡a2020';

  public static readonly FIRESTORE = {
    COLLECTIONS: {
      ADVERTISERS: 'advertisers',
    },
    KEYS: {
      MEDIA: 'mediaId',
      ACTIVE: 'active',
    },
  };

  //Shared medias
  public static readonly SHARED_MEDIAS_SINGULAR = 'otro';
  public static readonly SHARED_MEDIAS_PLURAL = 'otros';
  public static readonly STATUS_CODE = {
    SERVICE_UNAVAILABLE: 503
  };
  public static readonly TRIGGER_ACTIVATION_URL = environment.hipatiaServicesApi + 'trigger/cache-processor/activate-trigger';
  public static readonly TRIGGER_INACTIVATION_URL = environment.hipatiaServicesApi + 'trigger/cache-processor/inactivate-trigger';
  public static readonly TRIGGER_PUNCTUAL_TASK_URL = environment.hipatiaServicesApi + 'trigger/task-processor/punctual';
  public static readonly TRIGGER_PERIODIC_TASK_URL = environment.hipatiaServicesApi + 'trigger/task-processor/periodic';
  public static readonly TRIGGER_PURGE_SCHEDULE_JOBS_URL = environment.hipatiaServicesApi + 'trigger/schedule-processor/purge';
  public static readonly TRIGGER_TAG_TASK = 'task';
  public static readonly PUSH_SUBSCRIPTIONS_COLLECTION = 'push-subscriptions';
  public static readonly NEWSLETTERS_SUBSCRIPTIONS_COLLECTION = 'newsletters-subscriptions';

  public static readonly CSS_LOADER = `
    .accept-loader { width: :wpx;height: :hpx;border-radius: 50%;display: inline-block;border-top: 3px solid :hexacolor;border-right: 3px solid transparent;box-sizing: border-box;animation: rotation 1s linear infinite;} @keyframes rotation { 0% { transform: rotate(0deg);}100% { transform: rotate(360deg);}}`;

  public static readonly DEFAULT_IMAGE = '/assets/images/image-placeholder.png';
  public static readonly ACTIVE = 'Activo';
  public static readonly INACTIVE = 'Inactivo';
}

export const EMPTY_PLACEHOLDER = '-';

export enum FEATURE {
  THALIA
}

export const SNACK_BAR = {
  CONFIGURATION: {
    horizontalPosition: 'right' as MatSnackBarHorizontalPosition,
    verticalPosition: 'bottom' as MatSnackBarVerticalPosition,
    duration: 5000,
  },
  STYLE: {
    POSITION: 'fixed',
    LEFT: '0',
    TOP: '0',
    OPACITY: '0',
  }
};

export const ASYNC_VALIDATOR_PULSE = 500;
